import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { POST_OVERVIEW } from "../keys";
import { format } from "date-fns";

const usePostsOverview = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [POST_OVERVIEW, tenant.id, since, until],
    queryFn: () => ApiService.getPostOverview(since, until),
    select: (data) => data.data,
    placeholderData: {data: {
      Facebook: [],
      Instagram: [],
    }},
    staleTime: 0,
  });
};

export default usePostsOverview;
