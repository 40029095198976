import React from 'react';
import { Chrono } from 'react-chrono';
import usePersonSocialTimeline from '../query/hooks/usePersonSocialTimeline';
import { Spinner } from 'reactstrap';

const SocialTimeline = () => {
  const {data: items, isLoading: isLoadingTimeline} = usePersonSocialTimeline();

  if (!items || items.length === 0) {
    return null;
  }

  const chronoItems = items.map((item, index) => {
    let mediaType = 'IMAGE';
    if (item.media === 'facebook') {
      mediaType = 'IMAGE';
    } else if (item.media_type === 'VIDEO') {
      mediaType = 'VIDEO';
    } else if (item.media_type === 'CAROUSEL_ALBUM') {
      mediaType = 'IMAGE';
    } else if (item.media_type === 'IMAGE') {
      mediaType = 'IMAGE';
    }
    // styling class is stripped by xss
    const stats = `
      <div class="timeline-stats">
        <span>👥 ${item.engagement?.toLocaleString() || 0}</span>
        <span>👁️ ${item.impression?.toLocaleString() || 0}</span>
        <span>💰 ${item.value?.toLocaleString()} NOK</span>
      </div>
    `;

    return {
      date: new Date(item.timestamp),
      title: 'New post on ' + item.media,
      cardDetailedText: stats,
      cardTitle: item.message?.length > 100 ? item.message.substring(0, 97) + '...' : item.message,
      url: item.permalink || '',
      media: item.media && (item.s3_url || item.media_url) ? {
        type: mediaType,
        source: {
          url: item.s3_url || item.media_url,
          type: mediaType === 'VIDEO' ? 'mp4' : 'image',
        }
      } : undefined,
      items: []
    };
  });

  const chronoIcons = items.map((item) => {
    const iconUrl = item.media === 'facebook' 
      ? "https://img.icons8.com/ios-filled/100/4a90e2/facebook-new.png"
      : "https://img.icons8.com/ios-filled/100/E1306C/instagram-new.png";
    
    return {
      url: iconUrl,
      className: "timeline-icon"
    };
  });

  if (chronoItems.length === 0) {
    return (
      <div className="social-timeline">
        <div className="timeline-container">
          <div style={{ 
            width: '100%', 
            height: '200px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            color: 'var(--timeline-secondary-text)',
            fontSize: '1.1em',
            textAlign: 'center',
            backgroundColor: 'var(--timeline-card-bg)',
            borderRadius: '8px',
            padding: '20px'
          }}>
            <p>No timeline items to display yet.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="social-timeline">
      <div className="timeline-container">
        <div style={{ width: '100%', height: '100%' }}>
          {isLoadingTimeline && (
            <Spinner color="primary" />
          )}
          <Chrono
            items={chronoItems}
            mode="VERTICAL_ALTERNATING"
            disableToolbar={true}
            theme={{
              primary: 'var(--timeline-link)',
              secondary: 'var(--timeline-secondary-text)',
              cardBgColor: 'var(--timeline-card-bg)',
              cardForeColor: 'var(--timeline-text)',
              titleColor: 'var(--text-primary)',
              cardTitleColor: 'var(--timeline-text)',
              cardText: 'var(--timeline-secondary-text)',
              detailsColor: 'var(--timeline-secondary-text)',
              cardDetailsColor: 'var(--timeline-secondary-text)',
              titleColorActive: 'var(--text-primary)',
              nestedCardBgColor: 'var(--timeline-card-bg)',
              nestedCardDetailsBackGround: 'var(--timeline-card-bg)',
              nestedCardDetailsColor: 'var(--timeline-secondary-text)',
              nestedCardSubtitleColor: 'var(--timeline-secondary-text)',
              nestedCardTitleColor: 'var(--timeline-text)',
            }}
            parseDetailsAsHTML={true}
            focusActiveItemOnLoad={false}
            allowDynamicUpdate={true}
          >
            <div className="chrono-icons">
              {chronoIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.url}
                  alt={items[index].media}
                  className={icon.className}
                  style={{
                    width: '48px',
                    height: '48px',
                    marginBottom: '0px',
                    maxWidth: '48px',
                    maxHeight: '48px',
                    backgroundColor: 'var(--background-primary)'
                  }}
                />
              ))}
            </div>
          </Chrono>
        </div>
      </div>
    </div>
  );
}

export default SocialTimeline;
