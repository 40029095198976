import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const MediaUsageChart = ({ data = [], compareMode }) => {
  if (!Array.isArray(data)) {
    data = [];
  }

  const categories = data.map(item => item.media_type);
  const brandSeries = {
    name: 'Brand Audience',
    data: data.map(item => item.brand_percentage)
  };

  const comparisonSeries = compareMode ? {
    name: 'Comparison Audience',
    data: data.map(item => item.comparison_percentage)
  } : null;

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: null
    },
    xAxis: {
      categories,
      title: {
        text: null
      }
    },
    yAxis: {
      title: {
        text: 'Usage (%)'
      },
      max: 100
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'var(--text-primary)',
            textOutline: 'none',
            fontWeight: '500'
          }
        }
      }
    },
    series: compareMode ? [brandSeries, comparisonSeries] : [brandSeries],
    colors: ['#FFB81C', '#4A90E2'],
    credits: {
      enabled: false
    },
    legend: {
      enabled: compareMode
    }
  };

  return (
    <div style={{ minHeight: '400px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default MediaUsageChart;
