import React from 'react'
import { Redirect } from 'react-router-dom'
import CallbackPage from '../components/CallbackPage'
// Pages Component
import Dashboard from '../views/Dashboard'
import Fans from '../views/Fans'
import SettingsSponsorObject from '../views/SettingsSponsorObject'
import Calendar from '../views/Calendar'
import Arena from '../views/Arena'
import Reports from '../views/Reports'
import DataSources from '../views/DataSources'
import Brand from '../views/Brand'
import Onboarding from '../views/Onboarding'
import ActivateAccount from '../views/ActivateAccount'
import ClubTables from '../views/ClubTables'
import Media from '../views/Media'
import Partnere from '../views/Partnere'
import AdminPanel from '../views/AdminPanel'
import RegistrationPageAthlete from '../views/RegistrationPageAthlete.jsx'
import RegistrationPage from '../views/RegistrationPage.jsx'
import EmailNotificationPage from '../views/EmailNotificationPage.js'
import PersonDash from '../views/PersonDash.jsx'
import PersonSocials from '../views/PersonSocials.jsx'
import PersonAudience from '../views/PersonAudience.jsx' 
import DefaultLayout from '../layouts/DefaultLayout'
import CleanLayout from '../layouts/CleanLayout'
import PersonLayout from '../layouts/PersonLayout'
import PersonValue from '../views/PersonValue.jsx'
import PersonChat from '../views/PersonChat.jsx'
import AthleteLayout from '../layouts/AthleteLayout'
import ValuePerson from '../views/ValuePerson.jsx'
import PerformancePerson from '../views/PerformancePerson.jsx'
import PerformanceMedia from '../views/PerformanceMedia.jsx'
import AthleteAudience from '../views/AthleteAudience.jsx'
import AthleteSettings from '../views/AthleteSettings.jsx'
import RadixTest from '../views/RadixTest.jsx'
import SponsorContracts from '../views/SponsorContracts.jsx'
import AudienceInsights from '../views/AudienceInsights.jsx'
import PostsPage from '../views/PostsPage.jsx'

const authProtectedRoutes = [
  { path: "/person", layout: PersonLayout, component: PersonDash},
  { path: "/person-socials", layout: PersonLayout, component: PersonSocials},
  { path: "/person-audience", layout: PersonLayout, component: PersonAudience},
  { path: "/person-value", layout: PersonLayout, component: PersonValue},
  { path: "/person-chat", layout: PersonLayout, component: PersonChat },
  { path: "/onboarding", layout: CleanLayout, component: Onboarding },
  /*{ path: '/', layout: CleanLayout, exact: true, component: () => <Redirect to="/onboarding" /> },*/
  { path: "/calendar", layout: DefaultLayout, component: Calendar },
  { path: "/club/:tenantId", layout: DefaultLayout, component: ClubTables },
  { path: "/club", layout: DefaultLayout, component: ClubTables },
  { path: "/dashboard", layout: DefaultLayout, component: Dashboard },

  { path: "/fans", layout: DefaultLayout, component: Fans },
  { path: "/media", layout: DefaultLayout, component: Media },
  { path: "/arena", layout: DefaultLayout, component: Arena },
  { path: "/partnere", layout: DefaultLayout, component: Partnere },
  { path: "/reports", layout: DefaultLayout, component: Reports },
  {
    path: "/settings-sponsor-object",
    layout: DefaultLayout,
    component: SettingsSponsorObject,
  },
  {
    path: "/audience-insights",
    layout: DefaultLayout,
    component: AudienceInsights,
  },
  { path: "/data-sources", layout: DefaultLayout, component: DataSources },
  { path: "/sponsor-contracts", layout: DefaultLayout, component: SponsorContracts },
  { path: "/brand", layout: DefaultLayout, component: Brand },

  { path: "/value", layout: AthleteLayout, component: ValuePerson },
  { path: "/performance/source", layout:AthleteLayout, component: PerformanceMedia },
  { path: "/audience", layout: AthleteLayout, component: AthleteAudience },
  { path: "/settings", layout: AthleteLayout, component: AthleteSettings },
  { path: "/settings/:tab", layout: AthleteLayout, component: AthleteSettings },
  { path: "/performance", layout: AthleteLayout, component: PerformancePerson },
  { path: "/posts", layout: AthleteLayout, component: PostsPage },

  { path: "/admin", layout: DefaultLayout, component: AdminPanel },
  {
    path: "/",
    layout: CleanLayout,
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];


const publicRoutes = [
    { path: '/register', layout: CleanLayout, component: RegistrationPage},
    { path: '/PersonRegistration', layout: CleanLayout, component: RegistrationPageAthlete},
    { path: '/EmailNotificationPage', layout: CleanLayout, component: EmailNotificationPage},
    { path: '/activate/:uuid', layout: CleanLayout, component: ActivateAccount },
    { path: '/login/callback', layout: CleanLayout, component: CallbackPage },
    { path: '/radix', layout: ({children}) => <>{children}</>, component: RadixTest },
    // { path: "/test", layout: CleanLayout, component: AdminPanel },

]

export { authProtectedRoutes, publicRoutes }
