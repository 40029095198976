import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { POST_COMMENTS, } from "../keys";
import { useSelector } from "react-redux";

const usePostComment = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => ApiService.postComment(payload),
		onMutate: async (payload) => {
			queryClient.cancelQueries([POST_COMMENTS, tenant.id, 'instagram', payload.post_id]);
			let previousComments = queryClient.getQueryData([POST_COMMENTS, tenant.id, 'instagram', payload.post_id])
			 previousComments.data.data.map((comment) => {
				if (comment.id === payload.parent_comment_id) {
					comment.replies.data.push({
						id: Math.random(),
						comment: payload.comment,
						timestamp: new Date().toISOString(),
						username: 'You',
					});
				}
				return comment;
			});

			queryClient.setQueryData([POST_COMMENTS, tenant.id, 'instagram', payload.post_id], previousComments);
		},
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([POST_COMMENTS, tenant.id, 'instagram', variables.post_id]);
    },
		onError: (error, variables, context) => {
			queryClient.invalidateQueries([POST_COMMENTS, tenant.id, 'instagram', variables.post_id]);
		},
  });
};

export default usePostComment;
