import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

// Initialize the HighchartsMore module
if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

const RadialChart = ({ data = [], compareMode }) => {
  if (!Array.isArray(data)) {
    console.warn('RadialChart: data is not an array', data);
    data = [];
  }

  // Transform brand data into chart format
  const categories = data.map(item => item.characteristic || 'Unknown');
  
  // Create series data with proper null checks
  const brandSeries = {
    name: 'Brand Association',
    type: 'area',
    data: data.map(item => ({
      name: item.characteristic,
      y: item.percentage || 0
    }))
  };

  const comparisonSeries = compareMode ? {
    name: 'Comparison Group',
    type: 'area',
    data: data.map(item => ({
      name: item.characteristic,
      y: item.comparison_percentage || 0
    }))
  } : null;

  const chartStyles = getComputedStyle(document.documentElement);
  const backgroundColor = chartStyles.getPropertyValue('--chart-background').trim();
  const labelColor = chartStyles.getPropertyValue('--chart-text').trim();
  const gridColor = chartStyles.getPropertyValue('--chart-grid').trim();

  const options = {
    chart: {
      polar: true,
      type: 'area',
      backgroundColor: backgroundColor,
    },
    title: {
      text: null
    },
    pane: {
      size: '80%'
    },
    xAxis: {
      categories: categories.length > 0 ? categories : ['No Data'],
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '11px',
          color: labelColor
        }
      },
      gridLineColor: gridColor,
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      max: 100,
      labels: {
        format: '{value}%',
        style: {
          color: labelColor
        }
      },
      gridLineColor: gridColor,
      colorAxis: {
        maxColor: "var(--accent-color)",
        minColor: "var(--background-lighter)",
        min: 0,
        max: 10,
        labels: {
          format: "{value}%",
          style: {
            color: labelColor
          }
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function() {
        return this.points.map(point => 
          `<b>${point.series.name}</b><br/>
           ${point.x}: ${point.y.toFixed(1)}%<br/>`
        ).join('');
      }
    },
    plotOptions: {
      area: {
        fillOpacity: 0.3,
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: compareMode ? [brandSeries, comparisonSeries] : [brandSeries],
    colors: ['#FFB81C', '#4A90E2'],
    credits: {
      enabled: false
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default RadialChart;
