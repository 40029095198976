import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AGE_GROUP_LABELS = {
  '1': '18-24',
  '2': '25-34',
  '3': '35-44',
  '4': '45-54',
  '5': '55-64',
  '6': '65+'
};

const DemographicChart = ({ data = [], compareMode }) => {
  const chartOptions = useMemo(() => {
    if (!Array.isArray(data)) {
      console.warn('DemographicChart: data is not an array', data);
      return null;
    }

    // Extract age groups and ensure they are unique
    const categories = [...new Set(data.map(item => item['ag.age_group'] || 'Unknown'))];

    // Create series data with proper null checks
    const brandSeries = {
      name: 'Brand Audience',
      data: categories.map(category => {
        const item = data.find(d => d['ag.age_group'] === category);
        return item ? parseFloat(item.brand_percentage) || 0 : 0;
      })
    };

    const comparisonSeries = compareMode ? {
      name: 'Comparison Audience',
      data: categories.map(category => {
        const item = data.find(d => d['ag.age_group'] === category);
        return item ? parseFloat(item.comparison_percentage) || 0 : 0;
      })
    } : null;

    // Sort categories numerically and map to labels
    const sortedCategories = categories
      .sort((a, b) => parseInt(a) - parseInt(b))
      .map(category => AGE_GROUP_LABELS[category] || category);

    return {
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: sortedCategories.length > 0 ? sortedCategories : ['No Data'],
        title: {
          text: 'Age Groups'
        }
      },
      yAxis: {
        title: {
          text: 'Percentage (%)'
        },
        max: 100
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          dataLabels: {
            enabled: true,
            format: '{y}%',
            style: {
              color: 'var(--text-primary)',
              textOutline: 'none',
              fontWeight: '500'
            }
          }
        }
      },
      series: compareMode ? 
        [brandSeries, comparisonSeries].filter(Boolean) : 
        [brandSeries],
      colors: ['#FFB81C', '#4A90E2'],
      credits: {
        enabled: false
      }
    };
  }, [data, compareMode]);

  if (!chartOptions) {
    return <div>No demographic data available</div>;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default DemographicChart;
