import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { usePostsOverview, usePostComments } from "../query/hooks";
import { usePostComment } from "../query/mutations";
import { useFeatureFlagEnabled } from "posthog-js/react";

const PostsPage = () => {
  const [activeTab, setActiveTab] = useState('Facebook');
  const [selectedPost, setSelectedPost] = useState(null);
  const commentsEnabled = useFeatureFlagEnabled('posts-page-commenting');

  const {data: items, isFetching: isLoadingTimeline} = usePostsOverview();
  const {data: comments, isFetching: isLoadingComments} = usePostComments(activeTab.toLowerCase(), selectedPost?.id, commentsEnabled);
  const {mutate: postComment,  isLoading: isLoadingPostingComment} = usePostComment();

  useEffect(() => {
    const keys = Object.keys(items);

    setActiveTab(keys?.[0] ?? null);
  }, [items]);
  const posts = items?.[activeTab] ?? [];

  const handleReply = async (provider, postId, commentId) => {
    const msg = prompt("Enter your reply:");

    if (!msg) {
      return;
    }

    postComment({
      post_id: postId,
      comment: msg,
      parent_comment_id: commentId,
    })
  }

  const timestampToDiff = (timestamp) => {
    const diff = new Date() - new Date(timestamp);

    if (diff < (60 * 1000)) {
      return `${Math.floor(diff / 1000)}s`;
    }

    if (diff < (60 * 60 * 1000)) {
      return `${Math.floor(diff / (1000 * 60))}m`;
    }

    if (diff < (24 * 60 * 60 * 1000)) {
      return `${Math.floor(diff / (1000 * 60 * 60))}h`;
    }

    return `${Math.floor(diff / (1000 * 60 * 60 * 24))}d`;
  }

  return (
    <div className="container-fluid pt-5 p-0 value-person-view">
      <Modal isOpen={selectedPost !== null} toggle={() => setSelectedPost(null)}>
        {selectedPost && (
          <>
            <ModalHeader>
              {selectedPost?.message}
            </ModalHeader>
            <ModalBody>
              {selectedPost?.media_type === 'VIDEO' ? (
                <video controls src={selectedPost?.s3_url} alt="" className="post-modal-media" />
              ) : (
                <img src={selectedPost?.s3_url} alt="" className="post-modal-media" />
              )}
              <div className="post-modal-message">
                {selectedPost?.message}
              </div>
              <div className="post-modal-footer">
                <span>👀{selectedPost.impression}</span>
                <span>👏{selectedPost.engagement}</span>
                <span>💰{selectedPost.value}</span>
              </div>
              <div className="d-flex justify-content-between">
                <p>{format(new Date(selectedPost.timestamp), 'dd.MM yyyy')}</p>
                <a href={selectedPost.permalink} target="_blank" rel="noopener noreferrer">Link to post</a>
              </div>

              {commentsEnabled && activeTab === 'Instagram' && (
                <div className="d-flex flex-column mt-4">
                  <div className="d-flex align-items-center mb-2">
                    <p className="font-weight-bold text-white mb-0 mr-2">
                      Comments
                    </p>
                    {(isLoadingComments || isLoadingPostingComment) && <Spinner color="primary" size="sm" />}
                  </div>
                  {comments?.map(comment => (
                    <div key={comment.id} className="post-comments mt-2">
                      <p>
                        <a
                          href={`https://instagram.com/${comment.username}`}
                          target="_blank"
                          className="font-weight-bold mr-2 text-white"
                          rel="noopener noreferrer"
                        >
                        {comment.username}
                        </a>
                        {comment.text}
                      </p>
                      <div className="post-comments-footer">
                        <p>{timestampToDiff(comment.timestamp)}</p>
                        <p>{comment.like_count} {comment.like_count === 1 ? 'like' : 'likes'}</p>
                        <p><button onClick={() => handleReply(activeTab, selectedPost.id, comment.id)}>Reply</button></p>
                      </div>
                      <div className="pl-4">
                        {comment?.replies?.data?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))?.map?.(reply => (
                          <div key={reply.id} className="post-comments mt-2">
                            <p>
                              <a
                                href={`https://instagram.com/${reply.username}`}
                                target="_blank"
                                className="font-weight-bold mr-2 text-white"
                                rel="noopener noreferrer"
                              >
                              {reply.username}
                              </a>
                              {reply.text}
                            </p>
                            <div className="post-comments-footer">
                              <p>{timestampToDiff(reply.timestamp)}</p>
                              <p>{reply.like_count} {reply.like_count === 1 ? 'like' : 'likes'}</p>
                              <p><button onClick={() => handleReply(activeTab, selectedPost.id, comment.id)}>Reply</button></p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </ModalBody>
          </>
        )}
      </Modal>

      {isLoadingTimeline && <Spinner color="primary" />}
      <Nav pills className="navtab-bg center-pills mb-4">
        {
          Object.entries(items).map(([key, value], index) => (
            <NavItem item={value.id}>
              <NavLink
                href="#"
                className={classNames({ active: activeTab === key })}
                onClick={() => setActiveTab(key)}
              >
                {key}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <Row className="mb-4 px-4">
        {posts.map(item => (
          <Col key={item.id} xs={12} sm={6} md={4} lg={3} className="mb-3">
            <button className="post" onClick={() => setSelectedPost(item)}>
              <Card className="post-card">
                {item.media_type === 'VIDEO' ? (
                  <div className="post-card-media-video">
                    <video src={item.s3_url} alt="" className="post-card-media" />
                    <i className='fa fa-play post-card-media-video-icon'/>
                  </div>
                ) : (
                  <img src={item.s3_url} alt="" className="post-card-media" />
                )}
                <CardBody>
                  <div className="post-card-message">
                    {item.message}
                  </div>
                  <div className="post-card-footer">
                    <span>👀{item.impression}</span>
                    <span>👏{item.engagement}</span>
                    <span>💰{item.value}</span>
                  </div>
                  {format(new Date(item.timestamp), 'dd.MM yyyy')}
                </CardBody>
              </Card>
            </button>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PostsPage;
