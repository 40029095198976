import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { POST_COMMENTS } from "../keys";

const usePostsOverview = (provider, id, commentsEnabled) => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [POST_COMMENTS, tenant.id, provider, id],
    queryFn: () => ApiService.getPostComments(provider, id),
    select: (data) => data?.data?.data ?? [],
    placeholderData: {data: { data: [] }},
    staleTime: 0,
    enabled: commentsEnabled && provider === 'instagram' && !!id,
  });
};

export default usePostsOverview;
