import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from "highcharts/modules/map";
import mapNorway from '@highcharts/map-collection/countries/no/no-all.geo.json';
import { useThemeContext } from '../../../contexts/ThemeContext';

highchartsMap(Highcharts);

const GeographyMap = ({ data, compareMode = false }) => {
  const { isDarkMode } = useThemeContext();
  console.log('GeographyMap received data:', data);
  console.log('GeographyMap data type:', typeof data);
  console.log('Is data an array?', Array.isArray(data));

  const getSeriesData = () => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [{
        name: 'Brand Audience',
        data: [],
        type: 'map',
        nullColor: '#363449',
        mapData: mapNorway,
        keys: ['hc-key', 'value'],
        joinBy: 'hc-key',
        borderWidth: 1,
        borderColor: '#454358'
      }];
    }

    console.log('mapNorway:', mapNorway);
    
    // Map numeric region codes to Norway map region codes
    const regionMap = {
      '1': ['no-os', 'no-vk', 'no-in'],  // Oslo/Østlandet (Oslo, Viken, Innlandet)
      '2': ['no-td'],                     // Midt-Norge (Trøndelag)
      '3': ['no-vl', 'no-mr', 'no-ro'],  // Vestland (Vestland, Møre og Romsdal, Rogaland)
      '4': ['no-ag'],                     // Sørlandet (Agder)
      '5': ['no-no', 'no-tf']            // Nord-Norge (Nordland, Troms og Finnmark)
    };

    // Transform data into format expected by Highcharts
    const brandData = data.reduce((acc, item) => {
      const codes = regionMap[item.region];
      if (codes) {
        codes.forEach(code => {
          acc[code] = parseFloat(item.brand_percentage);
        });
      }
      return acc;
    }, {});

    console.log('Raw data:', data);
    console.log('Mapped brandData:', brandData);
    
    const brandSeries = Object.keys(brandData).map(key => [key, brandData[key]]);
    console.log('brandSeries:', brandSeries);

    if (!compareMode) {
      return [{
        name: 'Brand Audience',
        data: brandSeries,
        type: 'map',
        nullColor: '#363449',
        color: '#FFB800',
        mapData: mapNorway,
        keys: ['hc-key', 'value'],
        joinBy: 'hc-key',
        borderWidth: 1,
        borderColor: '#454358',
        states: {
          hover: {
            brightness: 0.1
          }
        },
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            textOutline: '1px #FFFFFF'
          },
          formatter: function() {
            if (this.point.value > 0) {
              return this.point.value.toFixed(1) + '%';
            }
            return null;
          }
        }
      }];
    }

    // Transform comparison data
    const comparisonData = data.reduce((acc, item) => {
      const codes = regionMap[item.region];
      if (codes) {
        codes.forEach(code => {
          acc[code] = parseFloat(item.comparison_percentage);
        });
      }
      return acc;
    }, {});

    const comparisonSeries = Object.keys(comparisonData).map(key => [key, comparisonData[key]]);

    return [
      {
        name: 'Brand Audience',
        data: brandSeries,
        type: 'map',
        nullColor: '#363449',
        color: '#FFB800',
        mapData: mapNorway,
        keys: ['hc-key', 'value'],
        joinBy: 'hc-key',
        borderWidth: 1,
        borderColor: '#454358',
        states: {
          hover: {
            brightness: 0.1
          }
        },
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            textOutline: '1px #FFFFFF'
          },
          formatter: function() {
            if (this.point.value > 0) {
              return this.point.value.toFixed(1) + '%';
            }
            return null;
          }
        }
      },
      {
        name: 'Comparison Audience',
        data: comparisonSeries,
        type: 'map',
        nullColor: '#363449',
        color: '#4A90E2',
        mapData: mapNorway,
        keys: ['hc-key', 'value'],
        joinBy: 'hc-key',
        borderWidth: 1,
        borderColor: '#454358',
        states: {
          hover: {
            brightness: 0.1
          }
        },
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            textOutline: '1px #FFFFFF'
          },
          formatter: function() {
            if (this.point.value > 0) {
              return this.point.value.toFixed(1) + '%';
            }
            return null;
          }
        }
      }
    ];
  };

  const getOptions = () => {
    const chartStyles = getComputedStyle(document.documentElement);
    const backgroundColor = chartStyles.getPropertyValue('--chart-background').trim();
    const textColor = chartStyles.getPropertyValue('--text-primary').trim();

    const getDataLabelStyle = () => ({
      enabled: true,
      useHTML: true,
      formatter: function() {
        if (this.point.value > 0) {
          const shadowStyle = isDarkMode ? 
            '2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000' :
            '2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff';
          
          return `<span style="color: ${isDarkMode ? '#FFFFFF' : '#000000'}; 
                              text-shadow: ${shadowStyle}; 
                              font-weight: bold;">
                    ${this.point.value.toFixed(1)}%
                  </span>`;
        }
        return null;
      }
    });

    const seriesData = getSeriesData().map(series => ({
      ...series,
      dataLabels: getDataLabelStyle()
    }));

    return {
      chart: {
        map: mapNorway,
        backgroundColor,
        height: 800,
        style: {
          fontFamily: 'var(--font-family-base)'
        }
      },
      title: {
        text: null
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
          theme: {
            fill: backgroundColor,
            stroke: textColor,
            states: {
              hover: {
                fill: 'var(--background-secondary)'
              },
              select: {
                fill: 'var(--background-secondary)'
              }
            }
          }
        }
      },
      colorAxis: {
        min: 0,
        max: 100,
        stops: [
          [0, '#FFF3D4'],    // Lightest yellow
          [0.3, '#FFE5A0'],  // Light yellow
          [0.6, '#FFD56B'],  // Medium yellow
          [0.8, '#FFC537'],  // Dark yellow
          [1, '#FFB800']     // Darkest yellow
        ],
        labels: {
          style: {
            color: textColor
          }
        }
      },
      legend: {
        enabled: true,
        title: {
          text: 'Audience %',
          style: {
            color: textColor
          }
        },
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        valueDecimals: 0,
        symbolHeight: 200,
        itemStyle: {
          color: textColor
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: {point.value:.1f}%'
      },
      series: seriesData,
      credits: {
        enabled: false
      },
      mapView: {
        joinBy: ['hc-key', 'code'],
        regions: [
          {
            name: 'Østlandet',
            codes: ['no-os', 'no-vk', 'no-in']
          },
          {
            name: 'Midt-Norge',
            codes: ['no-td']
          },
          {
            name: 'Vestlandet',
            codes: ['no-vl', 'no-mr', 'no-ro']
          },
          {
            name: 'Sørlandet',
            codes: ['no-ag']
          },
          {
            name: 'Nord-Norge',
            codes: ['no-no', 'no-tf']
          }
        ]
      }
    };
  };

  return (
    <div style={{ minHeight: '400px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={getOptions()}
        constructorType={'mapChart'}
      />
    </div>
  );
};

export default GeographyMap;
