import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ApiService from '../../services/ApiService';
import RadialChart from './charts/RadialChart';
import DemographicChart from './charts/DemographicChart';
import GeographyMap from './charts/GeographyMap';
import MediaUsageChart from './charts/MediaUsageChart';
import FilterPanel from './FilterPanel';

const useAudienceData = (compareMode, selectedFilters) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [insightsRes, demographicsRes, geographyRes, brandsRes, channelsRes] = await Promise.all([
          ApiService.getAudienceInsights(compareMode ? selectedFilters : []),
          ApiService.getAudienceInsightsDemographics(),
          ApiService.getAudienceInsightsFollowerGeography(),
          ApiService.getAudienceInsightsBrandAssociations(),
          ApiService.getAudienceInsightsEngagementChannels()
        ]);

        setData({
          insights: insightsRes.data,
          demographics: insightsRes.data.demographics,
          geography: insightsRes.data.geography,
          brandAssociations: insightsRes.data.brandAssociations,
          channels: insightsRes.data.mediaUsage
        });
      } catch (err) {
        console.error('Error loading audience insights:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [compareMode, selectedFilters]);

  return { loading, data, error };
};

const AudienceInsights = () => {
  const [compareMode, setCompareMode] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { loading, data, error } = useAudienceData(compareMode, selectedFilters);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters || []);
  };

  const handleCompareModeChange = (enabled) => {
    setCompareMode(enabled);
  };

  if (loading) {
    return <div>Loading insights...</div>;
  }

  if (error) {
    return <div>Error loading insights. Please try again later.</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <FilterPanel 
        selectedFilters={selectedFilters}
        onChange={handleFilterChange}
        onCompareModeChange={handleCompareModeChange}
        compareMode={compareMode}
      />
      
      <Row>
        <Col md="6">
          <Card className="mb-4">
            <CardHeader>Brand Associations</CardHeader>
            <CardBody>
              <RadialChart 
                data={data.brandAssociations} 
                compareMode={compareMode}
              />
            </CardBody>
          </Card>
        </Col>
        
        <Col md="6">
          <Card className="mb-4">
            <CardHeader>Demographics</CardHeader>
            <CardBody>
              <DemographicChart 
                data={data.demographics} 
                compareMode={compareMode}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Card className="mb-4">
            <CardHeader>Geography</CardHeader>
            <CardBody>
              <GeographyMap 
                data={data.geography}
                compareMode={compareMode}
              />
            </CardBody>
          </Card>
        </Col>

        <Col md="6">
          <Card className="mb-4">
            <CardHeader>Media Usage</CardHeader>
            <CardBody>
              <MediaUsageChart 
                data={data.channels}
                compareMode={compareMode}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AudienceInsights;
