import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/ApiService';

const useFilterOptions = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await ApiService.getAudienceInsightsFilterOptions();
        const filterOptions = response.data?.map(item => ({
          value: item.id,
          label: item.name
        })) || [];
        setOptions(filterOptions);
      } catch (err) {
        console.error('Error loading filter options:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  return { options, loading, error };
};

const FilterPanel = ({ 
  selectedFilters, 
  onChange,
  onCompareModeChange,
  compareMode 
}) => {
  const { options, loading, error } = useFilterOptions();

  const handleFilterChange = (selected) => {
    onChange(selected || []);
  };

  const handleCompareModeToggle = () => {
    onCompareModeChange(!compareMode);
  };

  if (loading) {
    return (
      <Card className="mb-4">
        <CardBody>Loading filter options...</CardBody>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mb-4">
        <CardBody className="text-danger">
          Error loading filter options. Please try again later.
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="mb-4">
      <CardBody>
        <Row>
          <Col md="6">
            <div className="mb-3">
              <label className="form-label">Filter Options</label>
              <Select
                isMulti
                name="filters"
                options={options}
                value={selectedFilters}
                onChange={handleFilterChange}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select filters..."
                noOptionsMessage={() => "No filter options available"}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="mb-3">
              <label className="form-label">Compare Mode</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="compareMode"
                  checked={compareMode}
                  onChange={handleCompareModeToggle}
                />
                <label className="form-check-label" htmlFor="compareMode">
                  Enable Comparison
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default FilterPanel;
